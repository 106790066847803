import styled from 'styled-components';

export const breakpoint = {
  mobile: 'only screen and (max-width: 768px)',
  desktop: 'only screen and (min-width: 768px)',
};

export const CenteredImage = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const CenteredContentBlock = styled.p`
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

export const ContentBlock = styled.div`
  background-color: rgba(0, 0, 90, 0.4);
  padding: 20px;
  margin: 0 auto;
  max-width: 770px;
`;

export const Grid = styled.div`
  display: flex;
  padding: 5px;
  background-color: rgba(0, 0, 90, 0.4);
  max-width: 800px;
  margin: 0 auto;

  @media ${breakpoint.mobile} {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  padding: 0 20px;

  img {
    max-width: 80%;
  }

  @media ${breakpoint.desktop} {
    width: 45%;

    &:first-child {
      border-right: 1px solid coral;
    }
  }
`;

export const Button = styled.button`
  display: block;
  padding: 10px;
  margin: 10px 0;
  text-align: center;
  border-radius: 15px;
  border: 1px solid coral;
  background-color: inherit;
  color: coral;
  font-size: 1.25em;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: yellow;
    border-color: yellow;
    text-decoration: none;
  }
`;
