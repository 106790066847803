import React from 'react';
import { Link } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const handleOnClick = (event) =>
  trackCustomEvent({
    category: 'navigation',
    action: 'User clicked link',
    label: event.target.innerText || event.target.alt || event.target.nodeName,
  });

const TrackLink = (props) => <Link {...props} onClick={handleOnClick} />;

export default TrackLink;
