import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import TrackLink from './trackLink';
import styled from 'styled-components';
import { breakpoint } from './styleBook';

const TopBar = styled.div`
  display: flex;

  @media ${breakpoint.desktop} {
    padding: 0 100px;
  }

  .slogan {
    flex-grow: 1;
    text-align: left;
    font-weight: bold;
  }
`;

const Links = styled.div`
  @media ${breakpoint.mobile} {
    display: flex;
    flex-direction: column;
    text-align: right;

    a {
      margin-bottom: 12px;
    }
  }

  a {
    color: white;

    &:hover {
      color: coral;
    }
  }
`;

const SeparatorWrapper = styled.span`
  @media ${breakpoint.mobile} {
    display: none;
  }

  padding: 10px;
`;

const Separator = () => <SeparatorWrapper>|</SeparatorWrapper>;

const Logo = styled.div`
  border-bottom: 1px solid coral;

  @media ${breakpoint.desktop} {
    text-align: left;
    margin: 20px 100px;
  }

  @media ${breakpoint.mobile} {
    text-align: center;
    margin: 20px 0;
  }
`;

const NaviBar = () => {
  return (
    <nav className="navigation">
      <TopBar>
        <div className="slogan">Täyden palvelun tietotekniikkatalo</div>

        <Links>
          <TrackLink to="/">Etusivu</TrackLink>
          <Separator />
          <TrackLink to="/vuokraus">Vuokraus</TrackLink>
          <Separator />
          <TrackLink to="/tiedostojen-palautus">Tiedostojen palautus</TrackLink>
          <Separator />
          <TrackLink to="/digitointi">Digitointi</TrackLink>
          <Separator />
          <TrackLink to="/yhteystiedot">Yhteystiedot</TrackLink>
          <Separator />
          <OutboundLink href="/verkkokauppa">Verkkokauppa</OutboundLink>
          <Separator />
          <TrackLink to="/english">In English</TrackLink>
        </Links>
      </TopBar>

      <Logo>
        <TrackLink to="/">
          <img src="/images/timetext.gif" alt="Timeimage Oy" />
        </TrackLink>
      </Logo>
    </nav>
  );
};

export default NaviBar;
