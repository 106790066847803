import React from 'react';
import { Helmet } from 'react-helmet';

const Head = () => (
  <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta
      name="description"
      content="ATK-Huolto palvelut. Ylläpidon konsultoinnit palvelut. Videotykin vuokraus. Tietokoneiden korjaus."
    />
    <meta
      name="keywords"
      content="Laitevuokraukset, verkkolaiteet, wlan, tarvikkeet, ohjelmistot. APC UPS huolto ja Eaton UPS huolto. Pc-huolto. videotykkien vuokraus, projektorien vuokraus, videoprojektorien vuokraus, pilvipalvelu, verkkokytkimien vuokraus, Palvelimen, Atk-palvelut"
    />
    <meta name="copyright" content="timeimage" />
    <meta name="author" content="Arto Kulju" />
    <meta name="email" content="arto.kulju@timeimage.fi" />
    <meta name="Language" content="FI" />
    <meta name="Distribution" content="Global" />
    <meta name="Rating" content="General" />
    <meta name="Robots" content="INDEX,FOLLOW" />
    <meta name="Revisit-after" content="31 Days" />
    <meta name="expires" content="googlebot" />
    <title>Timeimage Oy - videotykin ja tietokoneiden vuokraus</title>
    <script type="application/ld+json">
      {`
        {
          "@context": "http://schema.org",
          "@type": "LocalBusiness",
          "name": "Timeimage Oy",
          "url": "http://www.timeimage.fi",
          "description": "Videotykkien vuokraus, tietokoneiden korjaus ja ylläpito, lähiverkkojen suunnittelu, ylläpito ja toteutus.",
          "openingHours": "Mo,Tu,We,Th,Fr 09:00-17:00",
          "contactPoint": [{
          "@type": "ContactPoint",
          "telephone": "+358500434040",
          "contactType": "customer service"
          }]
        }
      `}
    </script>
  </Helmet>
);

export default Head;
