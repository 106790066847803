import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Head from './head';
import NaviBar from './naviBar';
import { breakpoint } from './styleBook';

const GlobalStyle = createGlobalStyle`
  body {
    color: white;
    font-family: sans-serif;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 51, 1);
  }

  h1, h2 {
    text-align: center;
  }

  a {
    color: coral;
    display: inline-block;
  }

  a:hover, a.active {
    color: coral;
    text-decoration: underline;
  }

  em {
    font-weight: bold;
    font-style: normal;
  }
  
  @media ${breakpoint.mobile} {
    img {
      max-width: 98%;
    }
  }

  .highlight {
    font-size: 1.25em;
  }
`;

const AppContainer = styled.div`
  padding: 10px;
  background-color: rgba(0, 0, 51, 1);
  margin: 0 auto;
  text-align: center;
`;

const Content = styled.div`
  @media ${breakpoint.desktop} {
    padding: 0 100px;
  }

  text-align: left;

  & > p {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

const Footer = styled.footer`
  margin-top: 100px;

  @media ${breakpoint.desktop} {
    padding: 100px;
  }

  @media ${breakpoint.mobile} {
    padding: 0;
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <Head />
      <GlobalStyle />

      <AppContainer>
        <NaviBar />

        <Content>{children}</Content>

        <Footer>
          Copyright &copy; 2021 Timeimage Oy - All rights reserved.
        </Footer>
      </AppContainer>
    </>
  );
};

export default Layout;
